/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var that = this;

        this.addIosTestToModernizr();
        //this.initDownloads();
        this.manageForms();
        this.manageHeader();
        this.manageSmoothScroll();
        this.manageGalleryLightbox();
        this.manageHero();
        this.manageGoogleMaps();
        this.manageFullHeightContainer();
        this.manageFloatingForm();
        this.manageSVG();
        this.manageIsotope();
        //this.disableAnimationsOnIOS();
        this.animationFadeInWaypoint();
				this.animationFadeInUpWaypoint();
				this.animationFadeInDownWaypoint();
				this.animationFadeInLeftWaypoint();
				this.animationFadeInRightWaypoint();
        this.animationZoomInDownWaypoint();
        this.manageFitText();
        this.manageParallaxBackground();

        if ($('html').hasClass("lt-ie9")) {
          document.body.onresize = function() {
            that.manageHeader();
            that.manageHero();
            that.manageFullHeightContainer();
            that.manageParallaxBackground();
            Waypoint.refreshAll();
          };
        } else {
          $(window).resize(function() {
            that.manageHeader();
            that.manageHero();
            that.manageFullHeightContainer();
            that.manageParallaxBackground();
            Waypoint.refreshAll();
          });
        }

        $(window).scroll(function() {
          that.manageScrollFade();
        });
      },
      addIosTestToModernizr: function() {
        //Test for IOS
        Modernizr.addTest('isios', function(){
          return navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false;
        });
      },
      initDownloads: function() {
        $(document).on("click", "a.file-download", function(e) {
          e.preventDefault();
          $.fileDownload($(this).attr('href'));
          return false;
        });
      },
      manageForms: function() {
        $('.ginput_container input[type="text"], .ginput_container input[type="email"], .ginput_container input[type="number"], .ginput_container input[type="tel"], .ginput_container textarea')
        .focus(function(){
          $(this).closest('.ginput_container').prev('.gfield_label').addClass('label-active');
        })
        .blur(function(){
          if( $(this).val() === "" ){
            $(this).closest('.ginput_container').prev('.gfield_label').removeClass('label-active');
          }
        });
      },
      manageFullHeightContainer: function() {
        $('.full-height-container').each(function() {
          /**
           * Setup variables
           */

          // Define the ID of the container
          var fullScreenContentContainer = $(this);
          // Define the inner container
          //var fullScreenContentInner = fullScreenContentContainer.find(".full-screen-inner");
          // Capture inner container height
          //var fullScreenContentInnerHeight = fullScreenContentInner.outerHeight();
          // Capture current window height
          var pageHeight = $(window).height();
          // Capture banner height (when no fixed position banner is present)
          //var siteHeaderHeight = $('.banner').not('.navbar-fixed-overlay .banner').outerHeight();
          // Capture banner height (when fixed position banner is present)
          //var siteHeaderHeightFixed = $('.banner').outerHeight();
          // Capture slider banner height - This is outside of the above containers
          //var sliderBannerHeight = fullScreenContentContainer.closest('section').find('.slider-banner').outerHeight();

          /**
           * Set the height of the full screen container
           */
          if( Modernizr.mq('only all and (min-width: 768px)') ) {
            fullScreenContentContainer.css( 'height', pageHeight );
          }
          /*
          else {
            if( $('body').hasClass('navbar-fixed-overlay') ) {
              fullScreenContentContainer.css( 'height', pageHeight - siteHeaderHeightFixed );
            } else {
              fullScreenContentContainer.css( 'height', pageHeight );
            }
          }
          */

          /**
           * Capture the new height of the full screen content container
           */

          //var newFullScreenContentContainerHeight = $(fullScreenContentContainer).outerHeight();

          /**
           * Vertically center slider content
           */

          /*
          $(fullScreenContentContainer).css({
            'padding-top': ( newFullScreenContentContainerHeight - fullScreenContentInnerHeight )/2,
            'padding-bottom': ( newFullScreenContentContainerHeight - fullScreenContentInnerHeight )/2
          });
          */
        });
      },
      manageHeader: function() {
				// Define header variation
				var header = $('.header-container');
				var bottomPositionedHeader = $('.bottom-positioned-header .header-container');
        var stickyHeaderWaypoint = $('#sticky-header-waypoint');

        $(header).removeClass('navbar-fixed-top navbar-scrolled');

				if( Modernizr.mq('only all and (min-width: 768px)') ) {

          // Position the sticky waypoint
          if( $('body').hasClass('bottom-positioned-header') ) {
            $(stickyHeaderWaypoint).css('bottom', $(bottomPositionedHeader).outerHeight() );
          }

					// Create the waypoint
					$(stickyHeaderWaypoint).waypoint(function(direction) {
						if(direction === 'down') {
							// If scroll is down
							$(header).removeClass('navbar-positioned-bottom');
							$(header).addClass('navbar-fixed-top navbar-scrolled');
						} else {
							// Otherwise
							$(header).addClass('navbar-positioned-bottom');
							$(header).removeClass('navbar-fixed-top navbar-scrolled');
						}
					});
				} else {
					// Find outer height of the header
					var headerBannerHeight = $(header).find('header.banner').outerHeight();

          // Set class so it's fixed to top
          $(header).addClass('navbar-fixed-top');

					// Set the way point elements 'top' attribute based on the height of the banner)
					// It is set as an absolute positioned element
          if( $('body').hasClass('bottom-positioned-header') ) {
					  $(stickyHeaderWaypoint).css('top', headerBannerHeight + 'px');
          }

					// Create the waypoint
					$(stickyHeaderWaypoint).waypoint(function(direction) {
						if(direction === 'down') {
							// If scroll is down
							$(header).addClass('navbar-scrolled');
						} else {
							// Otherwise
							$(header).removeClass('navbar-scrolled');
						}
					});
				}
			},
      manageHero: function() {
				/**
				 * Setup vars
				 */
				var cell = $('.hero-unit-container');
				var outerContainer = $(cell).find('.slide-inner');
				var innerContainer = $(outerContainer).find('.container');
				var windowHeight = $(window).height();
				var navHeight = $('.banner').outerHeight();

				/**
				 * Position content
				 */
				if( Modernizr.mq('only all and (min-width: 768px)') ) {
					// Desktop
					if( $('body').hasClass('navbar-fixed-overlay') && !$('body').hasClass('bottom-positioned-header') ) {
						// Fixed overlay headers
						$(outerContainer).css({
							'height': ( windowHeight ),
							//'padding-top': ( windowHeight - innerContainer.outerHeight() + navHeight )/2,
							//'padding-bottom': ( windowHeight - innerContainer.outerHeight() - navHeight )/2
						});
					} else {
						// Non fixed overlay headers
						$(outerContainer).css({
							'height': ( windowHeight ),
							//'padding-top': ( windowHeight - innerContainer.outerHeight() - navHeight )/2,
							//'padding-bottom': ( windowHeight - innerContainer.outerHeight() + navHeight )/2
						});
					}
				} else {
					// Mobile
					if( $('body').hasClass('navbar-fixed-overlay') ) {
						// Fixed overlay headers
						$(outerContainer).css({
							'height': ( windowHeight ),
							'padding-top': ( windowHeight - innerContainer.outerHeight() + navHeight )/2,
							'padding-bottom': ( windowHeight - innerContainer.outerHeight() - navHeight )/2
						});
					}
				}

				/**
				 * Setup slider
				 */
				var slickObject = $(cell).find('.slider-container');

        // slick event called before the slider is instantiated
        $(slickObject).on('init', function(event, slick) {
          if( slick.slideCount > 0 ) {
            $(cell).find('.slider-prev').on('click', function() {
              $(slickObject).slick('slickPrev');
            });
            $(cell).find('.slider-next').on('click', function() {
              $(slickObject).slick('slickNext');
            });
          }
        });

				$(slickObject).slick({
					pauseOnHover: 	true,
					mobileFirst: 		true,
					arrows: 				false,
					dots: 					false,
					fade: 					true
				});

        /**
         * Video popup
         */
        $('.play-button').magnificPopup({
          type: 'iframe'
        });
			},
      manageGoogleMaps: function() {
				// Find the data attributes of each Google map
				$('.gmap').each(function() {
					var myLat = $(this).data('lat');
					var myLng = $(this).data('lng');
					var myMapId = $(this).data('mapid');
					var myMapMarker = $(this).data('map-marker');
					var map_canvas = document.getElementById(myMapId);
					var myLatlng = new google.maps.LatLng(myLat, myLng);
					var map_options = {
						zoom: 15,
						center: myLatlng,
						mapTypeId: google.maps.MapTypeId.ROADMAP,
						scrollwheel: false,
						disableDefaultUI: false,
            draggable: false,
            styles: [
            {
              "featureType": "water",
              "elementType": "geometry.fill",
              "stylers": [
                {
                  "color": "#d3d3d3"
                }
              ]
            },
            {
              "featureType": "transit",
              "stylers": [
                {
                  "color": "#808080"
                },
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "geometry.stroke",
              "stylers": [
                {
                  "visibility": "on"
                },
                {
                  "color": "#b3b3b3"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "geometry.fill",
              "stylers": [
                {
                  "color": "#ffffff"
                }
              ]
            },
            {
              "featureType": "road.local",
              "elementType": "geometry.fill",
              "stylers": [
                {
                  "visibility": "on"
                },
                {
                  "color": "#ffffff"
                },
                {
                  "weight": 1.8
                }
              ]
            },
            {
              "featureType": "road.local",
              "elementType": "geometry.stroke",
              "stylers": [
                {
                  "color": "#d7d7d7"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "geometry.fill",
              "stylers": [
                {
                  "visibility": "on"
                },
                {
                  "color": "#ebebeb"
                }
              ]
            },
            {
              "featureType": "administrative",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#a7a7a7"
                }
              ]
            },
            {
              "featureType": "road.arterial",
              "elementType": "geometry.fill",
              "stylers": [
                {
                  "color": "#ffffff"
                }
              ]
            },
            {
              "featureType": "road.arterial",
              "elementType": "geometry.fill",
              "stylers": [
                {
                  "color": "#ffffff"
                }
              ]
            },
            {
              "featureType": "landscape",
              "elementType": "geometry.fill",
              "stylers": [
                {
                  "visibility": "on"
                },
                {
                  "color": "#efefef"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#696969"
                }
              ]
            },
            {
              "featureType": "administrative",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "visibility": "on"
                },
                {
                  "color": "#737373"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "labels.icon",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "labels",
              "stylers": [
                  {
                    "visibility": "off"
                  }
              ]
            },
            {
              "featureType": "road.arterial",
              "elementType": "geometry.stroke",
              "stylers": [
                {
                  "color": "#d6d6d6"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "labels.icon",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "geometry.fill",
              "stylers": [
                {
                  "color": "#dadada"
                }
              ]
            }
          ]};

					var map = new google.maps.Map(map_canvas, map_options);

          var marker;

					// To add the marker to the map, use the 'map' property
          if( myMapMarker ) {
  					marker = new google.maps.Marker({
  						position: myLatlng,
  						map: map,
              icon: myMapMarker
  					});
          } else {
            marker = new google.maps.Marker({
              position: myLatlng,
              map: map
            });
          }

					google.maps.event.addDomListener(window, 'load', this);
				});
			},
      manageScrollFade: function() {
        /**
         * Fade Out effect
         */
        if( !Modernizr.isios ) {
          var scrollTop = $(window).scrollTop(); // Scroll position from top
          var scrollWindow = scrollTop + $(window).height(); // Bottom of the page
          var opacityFadeSpeed = 1.8;

          $('.scroll-fade').each(function() {
            var scrollFadeElement = $(this).offset().top; // Content container from top

            if( scrollWindow > scrollFadeElement ) {

              $(this).css({
                'opacity': ( 1000 - ( scrollTop * opacityFadeSpeed ) ) / 1000
              });
            }
          });
        }
      },
      manageSmoothScroll: function() {
        $('a').smoothScroll();
      },
      manageGalleryLightbox: function() {
        $('.lightbox-gallery').each(function() { // the containers for all your galleries
          $(this).magnificPopup({
            delegate: 'a', // the selector for gallery item
            type: 'image',
            gallery: {
              enabled: true
            },
            image: {
              // options for image content type
              titleSrc: 'title'
            }
          });
        });
      },
      manageFloatingForm: function() {
        var floatingForm = $('.widget-floating-call-to-action');
        var windowHeight = $(window).height();
        var topDistance = parseInt( floatingForm.css('top') );
        var siteHeader = $('.banner');
        var pageOverlay = $('.page-overlay');

        if( !$.cookie('disableYSLFloatingBar') ) {
          $('#sticky-header-waypoint').waypoint(function(direction) {
  					if(direction === 'down') {
  						// If scroll is down
              if( !Modernizr.isios ) {
                $(floatingForm).addClass('bounceInRight');
              }
              $(floatingForm).css({
                'visibility': 'visible',
                'display': 'block',
              });
  					} else {
              $(floatingForm).fadeOut(function() {
                $(floatingForm).removeClass('bounceInRight');
                $(floatingForm).css('visibility', 'hidden');
              });
            }
  				});
        }

        // Set heights to allow scroll
        if( Modernizr.mq('only all and (min-width: 768px)') ) {
          $(floatingForm).css({
            'max-height': windowHeight - topDistance
          });
        } else {
          $(floatingForm).css({
            'max-height': windowHeight - siteHeader.outerHeight()
          });
        }

        // Set cookie and destroy DOM element
        $(floatingForm).find('.widget-close').on('click', function() {
          $.cookie('disableYSLFloatingBar', 'true', {
            expires: 1,
            path: '/'
          });

          $(floatingForm).fadeOut().remove();
        });

        // Show page overlay
        /*
        $(floatingForm).hover(
          function() {
            $(pageOverlay).fadeIn('fast');
          },function() {
            $(pageOverlay).fadeOut('fast');
          }
        );
        */
      },
      manageSVG: function() {
				$('img[src*="svg"]').each(function(){

					var $img = $(this);
					var imgID = $img.attr('id');
					var imgClass = $img.attr('class');
					var imgURL = $img.attr('src');

					$.get(imgURL, function(data) {
						// Get the SVG tag, ignore the rest
						var $svg = $(data).find('svg');

						// Add replaced image's ID to the new SVG
						if(typeof imgID !== 'undefined') {
							$svg = $svg.attr('id', imgID);
						}
						// Add replaced image's classes to the new SVG
						if(typeof imgClass !== 'undefined') {
							$svg = $svg.attr('class', imgClass + ' replaced-svg');
						}

						// Remove any invalid XML tags as per http://validator.w3.org
						$svg = $svg.removeAttr('xmlns:a');

						// Replace image with new SVG
						$img.replaceWith($svg);

					}, 'xml');

				});
			},
      manageIsotope: function() {

        var $container = $('.masonry-gallery').imagesLoaded(function() {
          // init
          $container.packery({
            'itemSelector': '.masonry-gallery-item'
          });
        });
      },
      disableAnimationsOnIOS: function() {
        if( Modernizr.isios ) {
          $('.animated').removeClass('animated');
        }
			},
      animationFadeInWaypoint: function() {
				$('.fadeIn--not-fired').waypoint(function(direction) {
					if(direction === 'down') {
						// If scroll is down
            if( !Modernizr.isios ) {
						  $(this.element).addClass('fadeIn');
						  $(this.element).removeClass('fadeIn--not-fired');
            }
            $(this.element).css('visibility', 'visible');
          }
				}, {
					offset: '100%'
				});
			},
			animationFadeInUpWaypoint: function() {
				$('.fadeInUp--not-fired').waypoint(function(direction) {
					if(direction === 'down') {
						// If scroll is down
            if( !Modernizr.isios ) {
						  $(this.element).addClass('fadeInUp');
						  $(this.element).removeClass('fadeInUp--not-fired');
            }
            $(this.element).css('visibility', 'visible');
          }
				}, {
					offset: '100%'
				});
			},
			animationFadeInDownWaypoint: function() {
  				$('.fadeInDown--not-fired').waypoint(function(direction) {
  					if(direction === 'down') {
  						// If scroll is down
              if( !Modernizr.isios ) {
						    $(this.element).addClass('fadeInDown');
						    $(this.element).removeClass('fadeInDown--not-fired');
              }
              $(this.element).css('visibility', 'visible');
            }
					}, {
  					offset: '100%'
  				});
				//}
			},
			animationFadeInLeftWaypoint: function() {
				$('.fadeInLeft--not-fired').waypoint(function(direction) {
					if(direction === 'down') {
						// If scroll is down
            if( !Modernizr.isios ) {
					    $(this.element).addClass('fadeInLeft');
					    $(this.element).removeClass('fadeInLeft--not-fired');
            }
            $(this.element).css('visibility', 'visible');
          }
				}, {
					offset: '100%'
				});
			},
			animationFadeInRightWaypoint: function() {
				$('.fadeInRight--not-fired').waypoint(function(direction) {
					if(direction === 'down') {
						// If scroll is down
            if( !Modernizr.isios ) {
					    $(this.element).addClass('fadeInRight');
					    $(this.element).removeClass('fadeInRight--not-fired');
            }
            $(this.element).css('visibility', 'visible');
          }
				}, {
					offset: '100%'
				});
			},
			animationfadeInUpWaypoint: function() {
				$('.fadeInUp--not-fired').waypoint(function(direction) {
					if(direction === 'down') {
						// If scroll is down
            if( !Modernizr.isios ) {
					    $(this.element).addClass('fadeInUp');
					    $(this.element).removeClass('fadeInUp--not-fired');
            }
            $(this.element).css('visibility', 'visible');
          }
				}, {
					offset: '100%'
				});
			},
			animationfadeInDownWaypoint: function() {
			  $('.fadeInDown--not-fired').waypoint(function(direction) {
				  if(direction === 'down') {
					  // If scroll is down
            if( !Modernizr.isios ) {
					    $(this.element).addClass('fadeInDown');
					    $(this.element).removeClass('fadeInDown--not-fired');
            }
            $(this.element).css('visibility', 'visible');
          }
				}, {
					offset: '100%'
				});
			},
			animationZoomInDownWaypoint: function() {
				$('.zoomInDown--not-fired').waypoint(function(direction) {
					if(direction === 'down') {
						// If scroll is down
            if( !Modernizr.isios ) {
					    $(this.element).addClass('zoomInDown');
					    $(this.element).removeClass('zoomInDown--not-fired');
            }
            $(this.element).css('visibility', 'visible');
          }
				}, {
					offset: '100%'
				});
			},
      manageFitText: function() {
				var maxFontSize = $('.fitText').data('max-font-size');
				var minFontSize = $('.fitText').data('min-font-size');

				if(maxFontSize) {
					$('.fitText').fitText(1.6, {
						minFontSize: minFontSize,
						maxFontSize: maxFontSize
					});
				}
			},
      /*
      manageParallaxBackground: function() {
        if( !$.browser.tablet && Modernizr.mq("(min-width: 768px)") && !$("html").hasClass("lt-ie9") ) {
          $(".parallax-container").removeClass("parallax-inactive");
          $(".parallax-container").parallax("50%", null, 0.4);
        } else {
          $(".parallax-container").addClass("parallax-inactive");
        }
      },
      */
      manageParallaxBackground: function() {
        if( !$.browser.tablet && Modernizr.mq("(min-width: 768px)") && !$("html").hasClass("lt-ie9") ) {
          $('.parallax-container').each(function() {
            $(this).removeClass('parallax-inactive');
            $(this).parallax('50%', null, 0.4);
          });
        } else {
          $('.parallax-container').each(function() {
            $(this).addClass('parallax-inactive');
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        var body = $('body');

        $(body).imagesLoaded( function() {
          $('.page-overlay').addClass('loaded');
          $('.page-overlay').fadeOut(1500);
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
